import React, { useEffect, useState, useRef } from 'react';
import Nav from './components/Nav';
import Credits from './components/Credits';
import { openFullscreen } from './components/Model';
import settings from './config.json';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import { Tween } from "react-gsap";
import { IconContext } from 'react-icons';
import {  IoShareOutline, IoCloseOutline } from "react-icons/io5";
import { RiFullscreenLine, RiCalendarLine, RiFacebookLine, RiTwitterLine } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import { CiHeart } from "react-icons/ci";
import { TextField, FormControl, ClickAwayListener } from '@mui/material';
import jsonp from 'jsonp';
import './App.css';

function AppInner() {

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState('');
  const [currentCue, setCurrentCue] = useState('');
  const [currentTimeSec, setCurrentTimeSec] = useState(-1); //current time of the video in seconds
  const [durationSec, setDurationSec] = useState(-1); // // current duration of the video in seconds
  const [isProjActive, setIsProjActive] = useState('');
  const [isProjFormActive, setIsProjFormActive] = useState([false,false,false]);
  const [isProjShareActive, setIsProjShareActive] = useState([false,false,false]);
  const [ creditsActive, setCreditsActive ] = useState(false);
  const [ showDefaultShare, setShowDefaultShare ] = useState();
  const [ open, setOpen] = useState(false);
  const [ formResult, setFormResult ] = useState([0,'']);
  const [val, setVal] = useState('');
  const navigate = useNavigate();

  const handleFormClick = (i) => {
    const arr = isProjFormActive.slice(0);
    arr[i] = true;

    setIsProjFormActive(arr);
    setOpen((prev) => !prev);
  }
  const handleProjShareClick = (i,title,url) => {
    console.log('share project');
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        const shareData = {
                title: title,
                url: url,
        }
        navigator.share(shareData);
    } else {
      const arr = isProjShareActive.slice(0);
      arr[i] = true;
  
      setIsProjShareActive(arr);
      setOpen((prev) => !prev);
    }
  }
  const handleSubmitClick = (tag) => {

    // setIsProjFormActive([false,false,false]);
    // setIsProjActive(true);
    const mcurl = `https://junctqin.us9.list-manage.com/subscribe/post-json?u=2c25165fb4a92bf284c3d7b83&id=5ca77672d7&f_id=001d1be1f0&EMAIL=${val}&tags=12760606,${tag}&b_2c25165fb4a92bf284c3d7b83_5ca77672d7=`;
    // {
    //   "mce-EMAIL":val,
    //   "tags":"12760606",
    //   "b_2c25165fb4a92bf284c3d7b83_5ca77672d7":""
    // }
    jsonp(mcurl, {param:'c'}, (err,data)=>{
      console.log('jsonp',err,data);
      if (data && data.result==='success'){
        setFormResult([1,'Thank You! We’ll let you know.']);
        setTimeout(handleClickAway,5000);
      } else {
        setFormResult([-1,'Error: '+data.msg]);
      }
    });
    // fetch(mcurl,{
    //   method: 'get',
    //   mode: 'no-cors',
    //   headers: { 'Content-Type': 'application/json' }
    // }).then((response)=>{
    //   // if (!response.ok) {
    //   //   throw new Error("Request failed.");  // Custom message on the error to throw
    //   // } else {
    //     return response.text();  // Call .json() only on good responses
    //   // }
    // }).then((data)=>{
    //   console.log(data);
    // })
    // .catch((error) => {  // Catch the error here
    //   console.log("error: "+error);
    // })
  };
  const handleCloseClick = () => {
    setCreditsActive(false);
  };

  const handleClickAway = (event) => {
    setFormResult([0,'']);
    setIsProjFormActive([false,false,false]);
    setOpen(false);
  };


  useEffect(() => {
    const aboutLink = document.querySelector('#about-link');

    aboutLink.addEventListener('click', function(event){
      if (creditsActive === false) {
        setCreditsActive(true);
      } else {
        setCreditsActive(false);
      }
    });


  });

  return (
    <div className='App'>
      <div className='fullscreen landing bg-dark'>
        { settings.options.map( (option,index) => (
          <div className={(option.active === true)? "project-wrap active" : "project-wrap"} key={index} data-index={index}>
            { (option.active === true) &&
              <>
                <a className='absolute h-full w-full' href={option.URL}>
                  <video className='object-cover object-center w-full' src={option.src} autoPlay={true} muted={true} playsInline={true} loop={true} />
                </a>
                <h2 className='relative'><img src={option.projLogo} alt="Project Logo" /></h2>
              </>
            }
            {option.active ===false &&
              <h2 className='text-xl'>{option.title}</h2>
            }
            <div className='w-full px-4' >
              { ( (option.active=== false) && (!isProjFormActive[index]) ) &&
                <>
                  
                  <a onClick={()=>handleFormClick(index)}>
                    <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                      <RiCalendarLine />
                    </IconContext.Provider>
                    <span className='underline text-sm'>Get a Reminder</span>
                  </a>
                </>
              }
              {isProjFormActive[index]===true &&
                <ClickAwayListener onClickAway={handleClickAway}>
                  {open ? (
                    <>
                      {formResult[0]!==1 && <div id="mc_embed_shell">
                        <div id="mc_embed_signup">
                          <form className='email-form validate' id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_self" noValidate="">
                            <div className='w-full flex gap-4 px-0 max-w-sm mx-auto items-center justify-between border-b border-white'>
                              <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                                  <GoMail />
                              </IconContext.Provider>
                              <FormControl>
                                <TextField
                                  id="mce-EMAIL"
                                  name="EMAIL"
                                  value={val}
                                  label="Enter your Email"
                                  variant="standard"
                                  onChange={(event) => {
                                    setVal(event.target.value);
                                  }}
                                />
                              </FormControl>
                              <input type="hidden" name="tags" defaultValue="12760606"></input>
                              <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                                <input type="text" name="b_2c25165fb4a92bf284c3d7b83_5ca77672d7" tabIndex="-1" defaultValue=""></input>
                              </div>
                              <button type="button" className='w-full max-w-12' onClick={()=>handleSubmitClick(option.reminderTag)}>submit</button>
                            </div>
                          </form>
                        </div>
                      </div>}
                      {formResult[0]!==0 && <div className="flex justify-center gap-2">
                        {formResult[0]===1 && 
                        <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                          <CiHeart />
                        </IconContext.Provider>}
                        {formResult[1]}
                      </div>}
                    </>
                  ) :  <a onClick={()=>handleFormClick(index)}>
                  <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                    <RiCalendarLine />
                  </IconContext.Provider>
                  <span className='underline text-sm'>Get a Reminder</span>
                </a>}
                </ClickAwayListener>
              } 
            </div>

            <div className='share-wrap'>
              { ( (option.active=== true) && (!isProjShareActive[index]) ) &&
                <>
                  <button onClick={()=>handleProjShareClick(index,option.title,option.URL)} id={option.title} className="share z-40 relative" data-share-title={option.title} data-share-url={option.URL}>
                      <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                          <IoShareOutline />
                      </IconContext.Provider>
                  </button>
                </>
              }
              {isProjShareActive[index]===true &&
                <ClickAwayListener onClickAway={handleClickAway}>
                  {open ? (
                  <Tween
                  from={{alpha:0}}
                  to={{alpha:1}}
                >
                  <div className="flex">
                    <a id="fb" rel="noopener noreferrer" target="_blank" className="ml-2 social fb"
                      href={option.fbURL}
                      data-size="large" data-share-title={option.URL} data-share-url={option.URL}> 
                      <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                        <RiFacebookLine />
                      </IconContext.Provider>
                    </a>
                    <a id="tw" rel="noopener noreferrer" target="_blank" className=" ml-2 social tw"
                      href={option.twURL}
                      data-size="large" data-share-title={option.title} data-share-url={option.URL}>
                        <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                        <RiTwitterLine />
                        </IconContext.Provider> 
                    </a>
                  </div>
                </Tween>
                  ) :  <button onClick={()=>handleProjShareClick(index,option.title,option.URL)} id={option.title} className="share z-40 relative" data-share-title={option.title} data-share-url={option.URL}>
                  <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                      <IoShareOutline />
                  </IconContext.Provider>
                </button>}
                </ClickAwayListener>
              } 
            </div>
          </div>
        )
      )}
        {creditsActive && 
          <>
            <Tween
            from={{ y:600,stagger: 0.4}}
            to={{ y:0,stagger:0.4}}
            duration={.5}
            >
              <div>
                <button id="close-btn" onClick={handleCloseClick}>
                <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                    <IoCloseOutline />
                </IconContext.Provider>
              </button>
              <Credits settings={settings.collective} /> 
              </div>
              
            </Tween>
          </>
        }
        
      </div>
      <Nav />
    </div>
  );
}

const App = () => <Router><AppInner /></Router>;

export default App;
